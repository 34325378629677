.navbar {
  background-color: #fa5;
}

.navbar-brand {
  font-family: "Abel", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.lol {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: #fff solid 2px;
  color: #fff;
  min-width: 1em;
  border-radius: 50%;
  vertical-align: middle;
  padding: 2px 5px 2px 5px;
}

.lol:before {
  content: "";
  float: left;
  width: auto;
  padding-bottom: 100%;
}
